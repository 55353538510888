<template>
  <div>
    <b-container fluid class="d-flex m-0 p-0">
      <div class="rectangle"></div>
      <div class="sticky-button">
        <a href="tel:916362609612">
        <img src="../assets/call.png" alt="" /></a>
        <br />
        <a href="https://wa.me/916362609612">
         <img src="../assets/watsapp.png" alt="" /></a>
        <br />
        <a href="mailto:consultant@manedesign.in" >
        <img src="../assets/email.png" alt="" /></a>
        <!-- <button @click="handleClick">Click Me</button> -->
      </div>
    </b-container>
  <!-- Container 1 -->
  <b-container fluid style="position: relative;">
    <b-row class="d-none d-md-block" style="position: relative; z-index: -1">
      <img
        src="../assets/bc.png"
        alt=""
        style="margin: 0%; padding: 0%"
      />
    </b-row>
    <b-row class="d-block d-md-none" style="position: relative; z-index: -1">
      <img src="../assets/bc-1.png" alt="" style="margin: 0%; padding: 0%" />
    </b-row>
  </b-container>

  <!-- Container 2 -->
  <b-container fluid style="position: absolute; top: 0; width: 100%; z-index: 1;">
    <div class="position-relative">
      <b-navbar
        toggleable="lg"
        type="light"
        class="mt-3 mt-md-4"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <!-- Logo -->
        <b-navbar-brand href="#" class="ml-md-4">
          <img
            src="../assets/logo.png"
            alt="Logo"
            class="navbar-logo"
          />
        </b-navbar-brand>

        <!-- Top-right buttons -->
        <div class="top-right-buttons d-none d-lg-flex">
          <router-link to="/blogs">
            <b-button variant="outline-primary" class="nav-button mr-3">
           <span style="font-size: 15px;color: black;margin-left: 7px;">Blogs</span> 
          </b-button>
          </router-link>
          <router-link to="/projects">
            <b-button variant="outline-primary" class="nav-button">
           <span style="font-size: 15px;color: black;margin-left: 7px;"> View Projects </span> 
          </b-button>
          </router-link>
          
        </div>

        <!-- Navbar toggler for mobile -->
        <b-navbar-toggle target="nav-collapse" class="d-lg-none"></b-navbar-toggle>

        <!-- Navbar collapse for mobile -->
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto d-lg-none">
            <b-nav-item class="my-2">
              <router-link to="/blogs">
              <b-button variant="outline-primary" class="nav-button w-100">
                Blogs
              </b-button>
              </router-link>

            </b-nav-item>
            <b-nav-item class="my-2">
              <router-link to="/projects">
              <b-button variant="outline-primary" class="nav-button w-100">
                View Projects
              </b-button>
              </router-link>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>

      </b-navbar>

    </div>
    <b-row class="align-items-center mt-4">
      <b-col lg="5" md="6" sm="12" class="mb-4 mb-md-0 text-content">
        <div class="content-wrapper">
          <p
            class="font-weight-bold"
            :style="{
              fontSize: 'clamp(1.2rem, 3vw, 2.2rem)',
              lineHeight: '1.2',
              color:black
            }"
          >
            Where Comfort <br class="d-none d-sm-block" />
            Meets Convenience <br class="d-none d-sm-block" />
            Find Your Perfect Space
          </p>
          <p
            :style="{
              fontSize: 'clamp(0.8rem, 1.5vw, 1rem)',
              lineHeight: '1.4'
            }"
          >
            Welcome to the heart of innovative design
            <br class="d-none d-sm-block" />
            We redefine excellence in the world of architectural design
          </p>
          <!-- <b-button
            class="mt-3 border-0 rounded-0"
            style="background-color: white;"
          >
            <span
              class="d-flex align-items-center"
              style="color: #8da9b2; font-weight: 600;"
              :style="{
                fontSize: 'clamp(0.8rem, 1.5vw, 1.125rem)'
              }"
            >
              About Us
              <img src="../assets/Arrow 1.png" alt="" class="ml-2" style="height: 1em; width: auto;" />
            </span>
          </b-button> -->
        </div>
      </b-col>
      <b-col lg="7" md="6" sm="12" class="mt-4 mt-md-0">
        <img
          src="../assets/logo1.png"
          alt=""
          class="img-fluid responsive-image"
        />
      </b-col>
    </b-row>
  </b-container>

  <!-- New Content -->
  <b-container fluid class="mt-5">
    <div class="services-container my-5">
      <h2 class="text-center mb-4 our-services head1">Our Services</h2>
      <div class="services-grid">
        <div v-for="(service, index) in services" :key="index" class="service-item">
          <div
            class="image-card"
            @mouseover="service.hover = true"
            @mouseleave="service.hover = false"
          >
            <div class="image-container" :class="{ hover: service.hover }">
              <img :src="service.image" class="card-img" alt="Service Image" />
              <div class="card-overlay">
                <h3 class="card-title">{{ service.title }}</h3>
                <div class="card-content">
                  <p>{{ service.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
  <b-container fluid class="no-padding no-margin" style="margin-top: 90px">
      <b-card no-body class="no-padding no-margin overflow-hidden">
        <b-row no-gutters class="no-padding no-margin">
          <b-col
            md="6"
            class="no-padding no-margin"
            style="background-color: black"
          >
            <b-card-body class="no-padding no-margin">
              <b-card-text class=" no-margin p-3">
                <b-row  class="why">
                  <p class="pp3">
                    Why MANE
                  </p>
                </b-row>

                <br />
                <p class="pp5">
                  We create harmonious interiors with balanced components of
                  comfort, beauty, and quality. Here you can receive a wide
                  spectrum of services in interior designing and architecting
                  starting with professional consultation up to complex
                  working-out of design-project as well as execution of
                  constructing and remounting-finishing works.
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" class="no-padding no-margin why-img-cont">
            <img
              src="../assets/LANDING PAGE/b1.jpg"
              alt=""
              style="margin: 0; padding: 0"
              class="rounded-0 no-padding no-margin why-img"
            />
            <!-- <b-card-img
            src="https://picsum.photos/400/400/?image=20"
            alt="Image"
            class="rounded-0 no-padding no-margin"
          ></b-card-img> -->
          </b-col>
        </b-row>
      </b-card>
    </b-container>
    <b-container style="margin-top: 20px;margin-bottom: 20px;">
  <p class="head1">
    How we Approach
  </p>

  <b-row style="margin-top: 30px">
    <b-col v-for="(step, index) in approachSteps" :key="index" md="6">
      <b-row style="margin-top: 20px;">
        <b-col v-for="(item, itemIndex) in step.items" :key="itemIndex">
          <img :src="item.image" alt="" :style="{ width: item.width }" />
          <p style="font-size: 15px; font-weight: 600; margin-top: 15px">
            {{ item.text }}
          </p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</b-container>
<b-container style="margin-top: 50px">
  <p
    class="head1"
    data-aos="fade-down"
    data-aos-easing="linear"
    data-aos-duration="1500"
  >
    Blog
  </p>
  <p class="head2" >
    Discover MANE’s latest and greatest Blogs
  </p>
  <b-row >
    <b-col
      v-for="(blog, index) in blogs"
      :key="index"
      :md="blog.size"
      style="position: relative; cursor: pointer"
      @click="blog.clickHandler()"
    >
      <img
        :src="require(`@/assets/${blog.image}`)"
        alt="" class="bdy-txt blg-txt"
      />
      <h3
        class="inputcard">
        {{ blog.title }}
      </h3>
    </b-col>
  </b-row>
</b-container>
<b-container class="test"  style="background-color: #ebf7fa !important;" fluid>
      <p class="head2" style="margin-top:20px; padding:10px;">
        Our clients send us a bunch of smiles with our services and love them
      </p>
      <div class="testimonial-container">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../assets/larr.png"
            alt=""
            @click="prev"
            style="cursor: pointer"
          />

          <!-- Mobile View -->
          <div class="testimonial-cards d-flex d-md-none">
            <b-col cols="12" class="testimonial">
              <b-card
                class="h-100"
                style="background-color: #ebf7fa !important;"
              >
                <p class="bdy-txt">
                  <img src="../assets/usc.png" alt="" />{{ visibleMessage
                  }}<img src="../assets/dsm.png" alt="" />
                </p>
              </b-card>
            </b-col>
          </div>

          <!-- Desktop View -->
          <div class="testimonial-cards d-none d-md-flex">
            <b-col
              v-for="(message, index) in visibleMessages"
              :key="index"
              cols="12"
              md="4"
              class="testimonial"
            >
              <b-card
                class="h-100"
                style="background-color: #ebf7fa !important;border:none !important; font-size: 16px;"
              >
                <p class="head2">Vignesh Kuldeep</p>
                <p class="bdy-txt">
                  <img src="../assets/usc.png" alt="" />{{ message
                  }}<img src="../assets/dsm.png" alt="" />
                </p>
              </b-card>
            </b-col>
          </div>

          <img
            src="../assets/rarr.png"
            alt=""
            @click="next"
            style="cursor: pointer"
          />
        </div>
      </div>
    </b-container>
  <b-container  style="background-color: black !important" fluid>
    
    <b-row >
      <b-col md="4"  class="text-center text-md-left footer" style="font-size: 12px;">
        <div class="foot1"> <img
          src="../assets/logo2.png"
          alt="Logo"
          height="100"
          class="mt-3 mt-md-5 ml-md-3"
          style="float: left; margin-left: 50px"
        />
      </div>

        <div class="foot2"> <p
          class="mt-5 mt-md-5 ml-md-3 ft2-txt">
     
          We create harmonious interiors with balanced components of comfort,
          beauty, and quality.
        </p>
        </div>
      </b-col>
      <b-col md="4" class="text-center text-md-left">
        <div class="mt-3 mt-md-5">
          <img
          src="../assets/SOCIAL MEDIA/email.png" width="60px"
          alt=""
          class="p-2"
        />
      <p style="font-size: 14px; font-weight: 600; color: white">
        consultant@manedesign.in
      </p>
      <div class="SocialMedia">
        <img
          src="../assets/SOCIAL MEDIA/Group.png" width="50px"
          alt=""
          class="p-2"
        />
        <img
          src="../assets/SOCIAL MEDIA/face.png" width="80px"
          alt=""
          class="p-2"
        />
        <img
          src="../assets/SOCIAL MEDIA/Path.png" width="50px"
          alt=""
          class="p-2"
        />

          </div>
        </div>
      </b-col>
      <b-col md="4" class="text-center text-md-left">
        <div
          class="mt-3 mt-md-5 d-flex justify-content-center align-items-center"
        >
          <form action="https://formspree.io/f/xyzgzjqq" method="POST">
            <b-row class="mb-2">
              <input
                type="text"
                name="Full Name"
                placeholder="Full Name"
                class="inputmob"
                style="
                 padding: 7px;
                    width: 100%;
                    background-color: black;
                    color: white;
                    border-bottom: 1px solid white;
                    font-size:14px;
                "
              />
            </b-row>
            <b-row class="mb-2">
              <input
                type="number"
                name="Mobile Number"
                placeholder="Mobile Number"
                class="inputmob"
                style="
                  padding: 7px;
                    width: 100%;
                    background-color: black;
                    color: white;
                    border-bottom: 1px solid white;
                    font-size:14px;
                "
              />
            </b-row>
            <b-row class="mb-2">
              <input
                type="text"
                name="Location"
                placeholder="Location"
                class="inputmob"
                style="
                  padding: 7px;
                    width: 100%;
                    background-color: black;
                    color: white;
                    border-bottom: 1px solid white;
                    font-size:14px;
                "
              />
            </b-row>
            <b-row>
              <button
                type="submit"
                style="
                  background-color: white;
                  margin:auto;
                  margin-top: 20px;
                  width: 50%;
                  border: none;
                "
              >
                <span style="font-size: 12px; color: black; font-weight: 600; "
                  >Submit</span
                >
              </button>
            </b-row>
          </form>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <hr style="color: white" />
        <p class="text-center text-md-left" style="color: white; font-size: 10px;">
          © 2015 - 2024 All Rights Reserved. Mane Design
        </p>
      </b-col>
    </b-row>
  </b-container>
</div>
  <!-- Responsive Container -->
 
  <!-- Additional content should come here -->
</template>
<script>



  export default {
    data() {
      return {
        services: [
        {
          title: "Architectural Design",
          image: require("../assets/SERVICES/s1.jpg"),
          description: "Our architectural design services don't just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories, embracing innovation, functionality, and aesthetics. Explore our portfolio of innovative designs and witness how our passion for architecture transforms blueprints into breathtaking reality. Step into a world where every line drawn is a step closer to turning your vision into a beautifully crafted space.",
          hover: false
        },
        {
          title: "Structural Design",
          image: require("../assets/SERVICES/s3.jpg"),
          description: "At our core, we engineer more than just structures; we lay the groundwork for aspirations to soar. Our structural                    design services are the bedrock of every visionary project.Combining expertise and innovation, we ensure every structure stands strong and true. From initial concept to the final blueprint, we create the framework for remarkable spaces that stand the test of time",
          hover: false
        },
        {
          title: "Electrical layout",
          image: require("../assets/SERVICES/s4.jpg"),
          description: " Invisible yet indispensable, our electrical and plumbing                    layouts are the unsung heroes behind every functional space.                    We intricately map out the veins of power and water,                    ensuring seamless functionality without compromising design.                    From switches to pipes, our detailed planning ensures a                    smooth, efficient, and problem-free experience.",
          hover: false
        },
        {
          title: "Interior Designs",
          image: require("../assets/SERVICES/s5.jpg"),
          description: "Our interior design services redefine spaces, balancing                    aesthetics and comfort. From concept to execution, we curate                    environments that reflect your style and personality. Our                    team's keen eye for detail and passion for creating inviting                    spaces ensure that every corner tells a story.",
          hover: false
        },
        {
          title: "3D Visualization",
          image: require("../assets/SERVICES/s2.jpg"),
          description: "Our 3D visualization services bring your ideas to life                    before they're built. Explore your space in stunning,                    lifelike detail. With cutting-edge technology, we create                    virtual environments where your vision leaps off the pageand into immersive, realistic views. Experience the power of visualizing your dream space before it's even constructed.",
          hover: false
        },
        {
          title: "Plumbing Layout",
          image: require("../assets/SERVICES/s6.jpg"),
          description: "Invisible yet indispensable, our electrical and plumbing                    layouts are the unsung heroes behind every functional space.                    We intricately map out the veins of power and water,                    ensuring seamless functionality without compromising design.                    From switches to pipes, our detailed planning ensures a                    smooth, efficient, and problem-free experience.",
          hover: false
        },
        // Add other services here...
      ],
      approachSteps: [
      {
        items: [
          { image: require('@/assets/ICONS/mc.png'), text: 'First meeting consultation', width: '40%' },
          { image: require('@/assets/ICONS/m.png'), text: 'Measurements', width: '45%' },
        ],
      },
      {
        items: [
          { image: require('@/assets/ICONS/as.png'), text: 'Agreement Signing', width: '54%' },
          { image: require('@/assets/ICONS/spl.png'), text: 'Space-planning layouts', width: '34%' },
        ],
      },
      {
        items: [
          { image: require('@/assets/ICONS/3d.png'), text: 'Interior photo-realistic 3D visualizations', width: '25%' },
          { image: require('@/assets/ICONS/ct.png'), text: 'Complete set of Technical documentation and specifications', width: '40%' },
        ],
      },
      {
        items: [
          { image: require('@/assets/ICONS/pro.png'), text: 'Procurement', width: '42%' },
          { image: require('@/assets/ICONS/os.png'), text: 'On-site designer’s supervision', width: '34%' },
        ],
      },
    ],
    blogs: [
      {
        image: 'a11.png',
        title: 'WHY ONE SHOULD PREPARE STRUCTURAL DETAILS FROM PROFESSIONALS BEFORE STARTING CONSTRUCTION',
        size: 8, // Bootstrap column size
        clickHandler: this.blog1, // Method to call on click
      },
      {
        image: 'a12.png',
        title: 'WHY ELECTRICAL LAYOUT IS IMPORTANT FOR BUILDING',
        size: 4,
        clickHandler: this.blog2,
      },
      {
        image: 'a13.png',
        title: 'IMPORTANCE OF CHOOSING THE RIGHT SITE',
        size: 4,
        clickHandler: this.blog3,
      },
      {
        image: 'a14.png',
        title: 'WHY ONE SHOULD FINALISE ELEVATION FROM PROFESSIONALS BEFORE STARTING CONSTRUCTION',
        size: 8,
        clickHandler: this.blog4,
      },
    ],
    name: "Vignesh Kuldeep",
      messages: [
        "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
        "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
        "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
        "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
        "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
      ],
    currentIndex: 0,
    itemsPerPage: 3,
      }
    },
    computed: {
    visibleMessage() {
      return this.messages[this.currentIndex];
    },
    visibleMessages() {
      const visible = [];
      for (let i = 0; i < 3; i++) {
        visible.push(
          this.messages[(this.currentIndex + i) % this.messages.length]
        );
      }
      return visible;
    },
  },
    methods:{
      projectDetails(){
      this.$router.push({ name: 'project1' });
    },
    blog1() {
    // Logic for blog 1
    console.log('Blog 1 clicked');
  },
  blog2() {
    // Logic for blog 2
    console.log('Blog 2 clicked');
  },
  blog3() {
    // Logic for blog 3
    console.log('Blog 3 clicked');
  },
  blog4() {
    // Logic for blog 4
    console.log('Blog 4 clicked');
  },
  prev() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.messages.length - 1;
      } else {
        this.currentIndex -= 1;
      }
    },
    next() {
      if (this.currentIndex === this.messages.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex += 1;
      }
    },
    }
  }
</script>
<style>
@import "@/assets/font.css";
body {
  overflow-x: hidden !important;
}

.our-services{
  color: black;  
      font-size: 32px;
      font-weight: 600;
      padding-top:20px;
}

.head1{
  color: black;  
  font-size: 32px;
  font-weight: 600;
 
}
.head2{
  color: black;  
  font-size: 18px;
  font-weight: 600;
}

.blg-txt{
  width: 100%; 
  height: 350px; 
  display: block;
   margin: 0 auto;
   margin-top: 20px; 
   font-size: 16px;
}

.testimonial-container {
  margin-top: 20px;
}
.testimonial-cards {
  overflow: hidden;
}
.testimonial {
  margin: 0 10px;
}
.pp3{
  color: white;
  font-size: 32px;
  text-align: left;
  margin-left: 40px;
  padding-top: 10%;
}
.pp5{
  color: white;
  font-size: 18px;
  text-align: left;
  margin-left: 40px;
  margin-top:5%;
}
.why{
  margin-top: 80px;
}


.h-100{
  width:98%;
}

.test{
  padding:40px auto;
  padding-bottom: 40px;
}
.foot2{
            font-size: 14px;
            font-weight: 500;
            color: white;
            margin-left:45%;
            text-align: left;
            margin-top:10%;
            margin-right: 15%;
}

.ft2-txt{
  margin-left:20% ;
  margin-top: 20%;
}

</style>
<style scoped>
@import "@/assets/font.css";
.sticky-button {
  background-color: #ffffff;
  border-radius: 10px 0px 0px 10px;
  z-index: 111;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.sticky-button img {
  padding: 6px 12px;

  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.sticky-button button:hover {
  background-color: #2a2c2e;
}

@media(max-width:960px){
  
  .footer{
    display:grid;
    flex-direction: column;

  }
  .foot1{
    margin:auto;
  }
 .foot2{
  display: block;
  padding-left: 0px;
  margin-top:0px;
  margin-left:0px;
 }
}

@media (max-width: 768px) {
  .hide {
    display: none !important;
  }
  .moblogo{
    margin-right: 0px !important;
  }
}
@media (max-width: 1200px) {
  .responsive-text {
    font-size: 0rem;
    margin-left: 20px;
  }
}
.responsive-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 80vh;
}

@media (max-width: 1260px) {
  .responsive-image {
    max-height: 60vh;
  }
}

@media (max-width: 767px) {
  .responsive-image {
    max-height:50vh;
  }

.why-img{
  width:100%;
  height:0px;
}


}

@media (max-width: 575px) {
  .responsive-image {
    max-height: 40vh;
  }
}

.left-align {
  text-align: center !important;
}

.btn.left-align {
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
}

@media (min-width: 992px) {
  .content-wrapper {
    text-align: left;
    margin-left: 10vh;
  }
}

@media (max-width: 767px) {
  .text-content {
    margin-top: 0;
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
}

@media (min-width: 992px) {
  .btn {
    justify-content: flex-start;
    padding-left: 0 !important;
  }
}

.navbar-logo {
  height: 4rem;
  max-height: 8rem;
  margin-left: 20px;
}

.nav-button {
  border: 1px solid black;
  color: black;
  border-radius: 0;
}

.nav-button:hover , .nav-button:active, .nav-button:visited {
  background-color: #6e6e6e;
  border: 1px solid black;
}

.top-right-buttons {
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.top-right-buttons .nav-button {
  margin-left: 1rem; /* Adds space between buttons */
}

@media (min-width: 992px) {
  .navbar-logo {
    height: 6rem;
  }
}

@media (max-width:640px){
  .h-100{
    width:96%;
  }

}

@media (max-width:400px){
  .h-100{
    width:92%;
  }

}
  

@media (max-width: 991.98px) {
  .navbar-nav {
    align-items: center;
  }
  
  .navbar-nav .nav-button {
    margin-bottom: 0.5rem; /* Adds space between buttons in mobile view */
  }
}
</style>

<style scoped>
.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}
.services-container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Default for larger screens */
  gap: 20px;
}

/* Responsive adjustments for mobile view */
@media (max-width: 767px) {
  .services-grid {
    grid-template-columns: 1fr; /* Show one card in a row */
    gap: 0; /* Remove gaps between cards */

  }
}

.service-item {
  width: 100%; /* Ensure each card takes full width of the grid cell */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  margin-top: 15px;
}

.image-card {
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 0;
  height: 100%;
  
}

.image-container {
  position: relative;
  aspect-ratio: 1 / 1; /* Maintain square aspect ratio */
  overflow: hidden;
  width: 100%; /* Full width of the parent */
  height: auto; /* Allow height to adjust based on width */
  max-width: 350px; /* Optional: Set a max width for larger screens */
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
  .image-container {
    height: 250px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 576px) {
  .image-container {
    height: 200px; /* Further adjust height for extra small screens */
  } 
  .footer{
    display:grid;
    flex-direction: column;

  }
  .foot1{
    margin:auto;
  }
 .foot2{
  display: block;
  margin-left: 0px;
  padding-left: 0px;
  margin-top:0px;
  margin-right:0px;
 }
 .ft2-txt{
  margin-left:0% !important;
  margin-top:5% ! important;
  text-align: center;

 }

}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: 0.5s ease;
}

.card-title {
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.card-content {
  color: white;
  text-align: center;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .card-overlay {
    height: auto;
    max-height: 30%;
  }

  .card-content {
    display: none;
  }

  .image-container:hover .card-overlay {
    height: 100%;
    max-height: 100%;
  }

  .image-container:hover .card-content {
    display: block;
  }

  .image-container:hover .card-img {
    transform: scale(1.1);
  }
}

@media (max-width: 767px) {
  .services-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .image-container {
    aspect-ratio: 1 / 1;
  }

 
  .card-overlay {
    height: auto;
    max-height: 30%;
  }

  .card-content {
    display: none;
  }

  .image-container:hover .card-overlay {
    height: 100%;
    max-height: 100%;
  }

  .image-container:hover .card-content {
    display: block;
    font-size: 0.8rem;
  }

  .card-title {
    font-size: 1rem;
  }

  .image-container:hover .card-img {
    transform: scale(1.1);
  }

  .pp3{
    font-size: 0.9rem;
    text-align: left;
    margin-left: 20px;
  }
  .pp5{
    font-size: 0.7rem;
    text-align: left;
    margin-left: 20px;
  }
  .why{
    margin-top: 40px;
  }

}


.inputcard{
          color: white;
          font-size: 16px;
          font-weight: 600;
          position: absolute;
          top: 85%;
          left:37%;
          transform: translate(-50%, -50%);
          text-align: left;
          background-color:rgba(0,0,0,0.1);
} 


@media (max-width: 480px) {
  .card-title {
    font-size: 0.9rem;
  }

  .card-content {
    font-size: 0.7rem;
  }

  .foot2{
    margin-right:4px;
    margin-top: 4px;
  }
}

@media (min-width:1540px){
  .head1{
    font-size:34px;
  }
  .head2{
    font-size: 24px;
  }
  .bdy-txt{
    font-size: 18px;
  }


}

@media (min-width:1024px){
  .head1{
    font-size:32px !important;
  }
  .head2{
    font-size: 20px !important;
  }
  .bdy-txt{
    font-size: 16px !important;
  }
}

@media((min-width:768px) and (max-width:1024px)){
  .head1{
    font-size:28px !important;
  }
  .head2{
    font-size: 18px !important;
  }
  .bdy-txt{
    font-size: 16px !important;
  }
}

@media(max-width:768px){
  .head1{
    font-size:24px !important;
  }
  .head2{
    font-size: 16px !important;
  }
  .bdy-txt{
    font-size: 14px !important;
  }
}





</style>
</style>
</style>